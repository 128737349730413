<template>
  <div class="insurance-companies">
    <custom-scrollbar>
      <div class="insurance-companies-inner">
        <div class="crm-container">
          <loading v-if="isCompaniesLoading">
            Загрузка страховых компаний
          </loading>

          <template v-else>
            <div class="crm-container-title mb-3">
              <span class="crm-title">
                Страховые компании
              </span>

              <b-button
                v-if="checkFeatureAccess({ name: 'Импорт', url: '/insurance-companies' })"
                :type="$const.SECONDARY_BUTTON"
                variant="outline-primary"
                class="ml-auto"
                @click="$router.push('/insurance-import')"
              >
                <span>Импорт</span>
              </b-button>

              <b-button
                v-if="checkFeatureAccess({ name: 'Экспорт', url: '/insurance-companies' })"
                :type="$const.SECONDARY_BUTTON"
                variant="outline-primary ml-3"
                @click="openExportModal"
              >
                <span>Экспорт</span>
              </b-button>

              <router-link
                v-if="checkFeatureAccess({ name: 'Добавление компании', url: '/insurance-companies' })"
                class="crm-button-link ml-3"
                to="/insurance-companies/new"
              >
                <b-button
                  :type="$const.PRIMARY_BUTTON"
                  variant="primary"
                  class="width-unset"
                >
                  <icon-cross
                    class="crm-icon-cross"
                  />
                  <span> Добавить компанию</span>
                </b-button>
              </router-link>
            </div>

            <div class="d-flex justify-content-between mb-3 w-100">
              <b-button
                v-if="checkFeatureAccess({ name: 'Возможность видеть скрытые компании', url: '/insurance-companies' })"
                :variant="getHidden ? 'primary' : 'outline-primary'"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                @click="getHidden = !getHidden"
              >
                {{ getHidden ? 'Показать не скрытые' : 'Показать скрытые' }}
              </b-button>

              <div class="">
                <router-link
                  v-if="checkFeatureAccess({ name: 'Кнопка Очередь импорта', url: '/insurance-companies' })"
                  to="/insurance-import-list"
                >
                  <b-button
                    :type="$const.SECONDARY_BUTTON"
                    variant="outline-primary ml-3"
                  >
                    <span>Очередь импорта</span>
                  </b-button>
                </router-link>

                <router-link
                  v-if="checkFeatureAccess({ name: 'Кнопка Проверка реестров от СК', url: '/insurance-companies' })"
                  to="/insurance-companies/import"
                  exact
                >
                  <b-button
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    class="ml-3"
                  >
                    Проверка реестров от СК
                  </b-button>
                </router-link>
              </div>
            </div>

            <div class="container-company">
              <div class="company-list">
                <insurance-company
                  v-for="company in companies"
                  :key="company.company.id"
                  :company="company"
                  class="company-list-item"
                />
              </div>
            </div>

            <div
              v-if="!isCompaniesLoading && !companies.length"
              class="my-3"
            >
              Ничего не найдено
            </div>
          </template>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { sortBy } from 'lodash';

// import Bus from '@/eventBus';

import Loading from '@/components/Loading';
import { mixinRoles } from '@/mixins';
import IconCross from 'assets/images/cross.svg';

import InsuranceCompany from '@/components/InsurancePrograms/InsuranceCompany';

export default {
  name: 'InsurancePrograms',
  page: {
    title: 'CRM Doctis - Страховые программы',
  },
  components: {
    IconCross,
    InsuranceCompany,
    Loading,
  },
  mixins: [mixinRoles],
  data() {
    return {
      companies: [],
      isCompaniesLoading: false,
      getHidden: false,
    };
  },

  watch: {
    getHidden() {
      this.loadingData();
    },
  },
  async created() {
    await this.loadingData();
  },
  methods: {
    openExportModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'InsurancePolicyExport',
      });
    },
    async loadingData() {
      this.isCompaniesLoading = true;
      try {
        const rawData = await this.$store.dispatch(this.$types.COMPANIES_FETCH, {
          getHidden: this.getHidden,
        });
        this.companies = sortBy(rawData, ['company.title']);
      } finally {
        this.isCompaniesLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-companies {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.insurance-companies-inner {
  padding: 30px 0;
}

.crm-container {
  width: 100%;
  flex-direction: column;
}
.crm-container-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-company {
  width: 100%;
}
.crm-title {
  @extend .page-title;
  margin-right: 30px;
}
::v-deep.crm-button{
  &.width-unset {
    width: unset;
    padding-left: 30px;
    padding-right: 30px;
    flex-shrink: 0;
    height: 40px;
  }

  & + .crm-button {
    margin-left: 20px;
  }

}

.company-list {
  margin: 0 -10px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}

.company-list-item {
  margin: 10px;
  width: calc(50% - 20px);
}
.crm-button-link {
  text-decoration: none;
  margin-left: 20px;
  flex-shrink: 0;

  .crm-icon-cross {
    margin-right: 15px;
  }
}

.button-import {
  margin-left: auto;
}

.button_transparent {
  background-color: transparent;
}

.policy-add-wrapper {
  // width: 100%;
  // justify-content: end;
  display: flex;
  margin-left: auto;
  margin-bottom: 25px;
}
</style>
